<template>
  <section class="VdGalleryBlock">
    <ul
      id="image-gallery"
      class="gallery list-unstyled cS-hidden">
      <li
        v-for="(image, index) in images"
        :key="index"
        :data-thumb="image.image_thumbnail_name">
        <picture class="Obj-Fit__VdGSldr">
          <source
            :srcset="image.image_name"
            media="(min-width: 1400px)">
          <source
            :srcset="image.image_name"
            media="(min-width: 992px)">
          <img
            :src="image.image_name"
            alt="Gallery image">
        </picture>
      </li>
    </ul>
  </section>
</template>

<script>
import "lightslider/dist/css/lightslider.min.css";
import "lightslider";

export default {
  name: "VehicleCarousel",
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  watch: {
    images: function() {
      this.$nextTick(() => {
        var slider = $("#image-gallery").lightSlider({
          pauseOnHover: true,
          adaptiveHeight: true,
          gallery: true,
          item: 1,
          thumbItem: 4,
          slideMargin: 4,
          speed: 500,
          pause: 5000,
          auto: true,
          loop: true,
          thumbMargin: 4,
          responsive: [
            {
              breakpoint: 1439,
              settings: {
                thumbItem: 6
              }
            },
            {
              breakpoint: 991,
              settings: {
                thumbItem: 4
              }
            }
          ],
          onSliderLoad: function() {
            $("#image-gallery").removeClass("cS-hidden");
          }
        });

        $(window).on("resize", function() {
          slider.refresh();
        });
      });
    }
  }
};
</script>
