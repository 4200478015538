<template>
  <div>
    <navbar />
    <router-view />
    <site-footer />
  </div>
</template>

<script>
import Navbar from "./NavBar";
import SiteFooter from "./SiteFooter";

export default {
  name: "Layout",
  components: {
    SiteFooter,
    Navbar
  }
};
</script>

<style></style>
