<template>
  <section
    v-match-heights="{
      el: ['.card'],// Array of selectors to fix
      disabled: [ // Array of heights where the plugin will not set the heights
        767, // If a string is provided this will be used as a max bound
        [920, 1200], // If an array is provided it will be used as min-max bounds in that order
      ]
    }"
    class="RecentlyAdded">
    <div class="FeaturedListingContainer">
      <div class="container">
        <h1>Featured listings</h1>
        <div class="row">
          <div
            v-for="vehicle in latestVehicles"
            :key="vehicle.vehicle_id"
            class="col-sm-6 col-md-4 col-lg-4 p-3">
            <router-link
              :to="viewVehicleObject(vehicle)"
              class="LinkBlock">
              <img
                v-show="vehicle.reserved"
                class="reservedimg"
                src="@/assets/img/reserved.png">
              <div
                :class="vehicle.reserved?'cardreserved':''"
                style="position: relative;">
                <div
                  v-show="vehicle.reserved"
                  class="reserved" />
                <div class="Obj-Fit__RecentlyAdded">
                  <figure
                    v-lazy:background-image="imgObj(vehicle)"
                    class="card-img-top layer" />
                </div><!-- /Obj-Fit__RecentlyAdded -->
                <div class="Card-CounterBox d-flex justify-content-between" /><!-- /Card-CounterBox -->
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <p class="card-text-left">
                        {{ price(vehicle) }}
                      </p>
                      <h5 class="card-title-left">
                        {{ vehicle.display_name }}
                      </h5>
                      <p class="Card-Additions-left">
                        {{ vehicle.year }} • {{ vehicle.mileage }}km • {{ manualOrAuto(vehicle) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div><!-- /card -->
            </router-link>
          </div>
        </div><!-- /row -->
        <div class="row">
          <div class="col-lg-12 view_more">
            <router-link to="/rent">
              VIEW MORE
            </router-link>
            <i class="fas fa-chevron-down" />
          </div>
        </div><!-- /row -->
      </div>
    </div>
  </section>
  <!-- RecentlyAdded -->
</template>

<script>
import swal from "sweetalert2";

export default {
  name: "RecentlyAdded",
  data() {
    return {
      latestVehicles: []
    };
  },
  created() {
    this.$http
      .get('bankds/graduate/rentalvehicle/latestvehicles')
      .then(response => {
        this.latestVehicles = response.data.data;
      })
      .catch(error => {
        if (error.response) swal("Error", error.response.data.message, "error");
      });
  },
  methods: {
    viewVehicleObject(vehicle) {
      return {
        name: 'rentview',
        params: {
          slug: vehicle.slug,
          vehicle_id: vehicle.id,
          search: this.$route.query
        }
      };
    },
    imgObj(vehicle) {
      return {
        src: vehicle.main_image,
        loading: "https://img.overtake.co.za/global/grid.svg"
      }
    },
    manualOrAuto(vehicle) {
      if (vehicle.attributes.man_or_aut) {
        return vehicle.attributes.man_or_aut;
      } else {
        return null;
      }
    },
    price(vehicle) {
      if(vehicle.from_price === null) {
        return 'Not Available'
      }else {
        return 'From '+vehicle.from_price+' pm'
      }
    }
  }
};
</script>

<style scoped>
  .layer[lazy="loading"] {
    background-color: #35404f;
    background-size: 25%;
  }
  .layer {
    padding-bottom: 100%;
    width: 100%;
  }
  .layer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .layer {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
</style>
