import Vue from "vue";
import App from "./App";
import "./registerServiceWorker";
import Auth from "./packages/auth";
import VeeValidate from "./packages/validation";
import VueLazyload from "vue-lazyload";
import VueMatchHeights from "vue-match-heights";
import AxiosPlugin from "./packages/axios";
import VueBrowserUpdate from "vue-browserupdate";
import Vue2Crumbs from "vue-2-crumbs";
import router from "./router";
import helpers from "./packages/helpers.js";
import { store } from "./store/index";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "@/assets/css/custom.css";
import "sweetalert2/dist/sweetalert2.min.css";

//WebFont import
import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Poppins:200,300,400,500,600,700,800"]
  }
});

//Browser update if your browser is too old
Vue.use(VueBrowserUpdate, {
  options: {
    reminder: 0,
    required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
    unsupported: true,
    container: document.body
  }
});

Vue.config.productionTip = false;

//General helpers file for common methods
Vue.use(helpers);

//Global validation
Vue.use(VeeValidate, { inject: false });

Vue.use(Auth);

//Lazy load images
Vue.use(VueLazyload);

//Used to make the thumbnail boxes all the wsame height
Vue.use(VueMatchHeights, {
  disabled: [768]
});

//Axios plugin which contains Axios and Axios setup
Vue.use(AxiosPlugin);

//Breadcrumbs
Vue.use(Vue2Crumbs);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
