import axios from "axios";
import swal from "sweetalert2";

const AxiosPlugin = {
  install(Vue) {
    //Default Lumen API calls
    Vue.prototype.$http = axios;
    //Set the base API URL
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    //Check to see if the token has expired on app refresh
    Vue.auth.getToken();

    //Request
    axios.interceptors.request.use(
      config => {
        config.headers.Apikey = process.env.VUE_APP_API_KEY;
        config.headers.Apisite = process.env.VUE_APP_API_SITE;
        config.headers.Authorization = "Bearer " + Vue.auth.getToken();
        config.headers.Accept = "application/json";
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    //Response
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (!error.response) {
          swal("Network down", "We are having network problems.", "error");
        } else {
          let statusCode = error.response.data.status_code;
          switch (statusCode) {
            case 401:
              swal(
                statusCode.toString(),
                "You are unauthorized to perform this action.",
                "error"
              );
              break;
            case 422:
              Vue.helpers.processError(error);
              break;
            case 404:
            case 400:
              swal(statusCode.toString(), error.response.data.message, "error");
              break;
            case 500:
              swal(
                statusCode.toString(),
                "We're having some problems on the server.",
                "error"
              );
              break;
          }
        }

        return Promise.reject(error);
      }
    );
  }
};

export default AxiosPlugin;
