import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default function() {
  VeeValidate.Validator.extend("verify_id_number", {
    getMessage: () => `The ID is not a valid South African ID number.`,
    validate: value => {
      const idNumber = value;
      var correct = true;

      if (idNumber.length !== 13) {
        correct = false;
      }

      // get first 6 digits as a valid date
      var tempDate = new Date(
        idNumber.substring(0, 2),
        idNumber.substring(2, 4) - 1,
        idNumber.substring(4, 6)
      );

      var id_date = tempDate.getDate();
      var id_month = tempDate.getMonth();

      if (
        !(
          tempDate.getYear() === Number(idNumber.substring(0, 2)) &&
          id_month === Number(idNumber.substring(2, 4) - 1) &&
          id_date === Number(idNumber.substring(4, 6))
        )
      ) {
        correct = false;
      }

      //SA citizen
      // if (parseInt(idNumber.substring(10, 11)) !== 0) {
      //   correct = false;
      // }

      // apply Luhn formula for check-digits
      var tempTotal = 0;
      var checkSum = 0;
      var multiplier = 1;
      for (var i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
          tempTotal =
            parseInt(tempTotal.toString().charAt(0)) +
            parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = multiplier % 2 === 0 ? 1 : 2;
      }

      if (checkSum % 10 !== 0) {
        correct = false;
      }
      return correct;
    }
  });
  VeeValidate.Validator.extend("is_email_unique", {
    getMessage: () => `The email address has already been used.`,
    validate: value => {
      return this.$http
        .post("/api/validate/email", { email: value })
        .then(response => {
          return {
            valid: response.data.valid,
            data: {
              message: response.data.message
            }
          };
        });
    }
  });
  VeeValidate.Validator.extend("secure_password", {
    getMessage: () =>
      `Your password needs to be at least 6 characters including one; Upper Case, Lower Case, Symbol, Number.`,
    validate: function(value) {
      return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(
        value
      );
    }
  });
}
