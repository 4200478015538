import swal from "sweetalert2";
import qs from "query-string";

import buildUrl from "@/mixins/urlBuilder";

export default {
  mixins: [buildUrl],
  data() {
    return {
      vehicles: [],
      pagination: {
        current_page: 1
      },
      queryString: "",
      loadingWizard: false,
      noVehicles: false
    }
  },
  computed: {
    currentPageTotal() {
      return (this.pagination.current_page - 1) * this.pagination.per_page + 1;
    },
    vehicleCount() {
      return this.vehicles.length;
    }
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    paginate() {
      let searchObject = {
        path: "",
        page: this.pagination.current_page,
        q: this.$route.query.q,
        make: this.$route.query.make,
        model: this.$route.query.model,
        province: this.$route.query.province,
        min_price: this.$route.query.min_price,
        max_price: this.$route.query.max_price,
        min_year: this.$route.query.min_year,
        max_year: this.$route.query.max_year,
        min_mileage: this.$route.query.min_mileage,
        max_mileage: this.$route.query.max_mileage
      };
      this.buildUrl(searchObject);
    },
    getVehicles(url) {
      this.queryString = "?" + qs.stringify(this.$route.query, { sort: false });

      this.setLoading(true);
      this.$http
        .get(`${url}${this.queryString}`)
        .then(response => {
          this.vehicles = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.noVehicles = response.data.data.length === 0;
          this.setLoading(false);
        })
        .catch(error => {
          this.setLoading(false);
          if (error.response)
            swal("Error", error.response.data.message, "error");
      });
    }
  }

}
