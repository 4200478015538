<template>
  <div class="Vd_FullSpecs">
    <div class="row no-gutters">
      <div class="col-xl-3">
        <div
          id="v-pills-tab"
          class="nav flex-column nav-pills"
          role="tablist"
          aria-orientation="vertical">
          <a
            id="v-pills-general-tab"
            data-id="link_tab"
            data-text="general"
            class="nav-link active show"
            data-toggle="pill"
            href="#v-pills-general"
            role="tab"
            aria-controls="v-pills-general"
            aria-selected="false"><b>General</b></a>
          <a
            id="v-pills-convenience-tab"
            data-id="link_tab"
            data-text="convenience"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-convenience"
            role="tab"
            aria-controls="v-pills-convenience"
            aria-selected="true"><b>Convenience</b></a>
          <a
            id="v-pills-performance-tab"
            data-id="link_tab"
            data-text="performance"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-performance"
            role="tab"
            aria-controls="v-pills-performance"
            aria-selected="false"><b>Performance</b></a>
          <a
            id="v-pills-interior-tab"
            data-id="link_tab"
            data-text="interior"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-interior"
            role="tab"
            aria-controls="v-pills-interior"
            aria-selected="false"><b>Interior</b></a>
          <a
            id="v-pills-safety-tab"
            data-id="link_tab"
            data-text="safety"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-safety"
            role="tab"
            aria-controls="v-pills-safety"
            aria-selected="false"><b>Safety</b></a>
          <a
            id="v-pills-dimensions-tab"
            data-id="link_tab"
            data-text="dimensions"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-dimensions"
            role="tab"
            aria-controls="v-pills-dimensions"
            aria-selected="false"><b>Dimensions</b></a>
          <a
            v-show="reconList.length"
            id="v-pills-recon-tab"
            data-id="link_tab"
            data-text="reconditioning"
            class="nav-link"
            data-toggle="pill"
            href="#v-pills-recon"
            role="tab"
            aria-controls="v-pills-recon"
            aria-selected="false"><b>Reconditioning</b></a>
        </div><!-- /nav-pills -->
      </div><!-- /col -->
      <div class="col-xl-9">
        <div
          id="v-pills-tabContent"
          class="tab-content">
          <div
            id="v-pills-general"
            class="tab-pane fade active show"
            role="tabpanel"
            aria-labelledby="v-pills-general-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Mileage</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ vehicle.mileage }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Body</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.type }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Cylinders</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.cylinders }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Drive Wheels</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.drive_whee }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Capacity</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.capacity }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Fuel Tank Size</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.fuel_cap }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Door</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.doors }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Gearbox</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.gearbox }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Fuel Type</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.fuel_type }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Gears</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.no_gears }}</p>
                </div><!-- /col -->
              </div><!-- /row -->

              <div class="row">
                <div class="col-lg-5">
                  <p><span>Make</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.brand }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Range</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.range }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Model</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.model }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /general-tab -->
          <div
            id="v-pills-convenience"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-convenience-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Aircon</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.aircon }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Xenon</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.xenon }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Aux</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.aux }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Mirrors</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.el_mirr }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Steering</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.steer_type }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Power Steering</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.pwr_strng }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Service Intervals</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.serv_int1 }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /convenience-tab -->
          <div
            id="v-pills-performance"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-performance-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Power</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.power }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Torque</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.torque }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>0 - 100km/h</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.acc100_fin }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Top Speed</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.top_final }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Co2 emissions</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.co2_fuel2 }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Fuel Avg</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.fuel_avg }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Fuel Urban</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.fuel_urban }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Fuel Highway</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.fuel_hway }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /performance-tab -->
          <div
            id="v-pills-interior"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-interior-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>CD Player</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.cd_player }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Leather</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.leather }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Electric Windows</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.el_win }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Folding Rear Seats</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.seat_split }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Isofix</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.isofix }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Seats</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.seats }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Heated Seats</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.heatedseat }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Power Seats</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.pwr_seats }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Cruise Control</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.cruise_cnt }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /interior-tab -->
          <div
            id="v-pills-safety"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-safety-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Airbags</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.air_bag_no }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Airbag Drivers</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.air_bag_dr }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Airbag Passenger</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.air_bag_pa }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Airbag Sides</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.air_bag_si }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Airbag Summary</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.airbag_det }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>ABS</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.abs }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Brake Summary</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.brak_hydra }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>High Level Brake Light</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.hi_brake }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Central Locking</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.cnt_lock }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /safety-tab -->
          <div
            id="v-pills-dimensions"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-dimensions-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Axle</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>4X{{ attributes.wheels_dri }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Tyre Front</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.tyre_front }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Tyre Rear</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.tyre_rear }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Gross Weight</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.gross }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Ground Clearance</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.gr_clear }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Height</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.height }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Length</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.length }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Wheelbase</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.wh_base }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
              <div class="row">
                <div class="col-lg-5">
                  <p><span>Width</span></p>
                </div><!-- /col -->
                <div class="col-lg-7">
                  <p>{{ attributes.width }}</p>
                </div><!-- /col -->
              </div><!-- /row -->
            </div><!-- /VdTabContent -->
          </div><!-- /dimensions-tab -->
          <div
            v-show="reconList.length"
            id="v-pills-recon"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="v-pills-recon-tab">
            <div class="VdTabContent">
              <div class="row">
                <div class="col-12">
                  <img
                    :src="vehicleDamageImage"
                    alt="Car Damage"
                    class="img-fluid mx-auto d-block">
                </div>
                <!-- /col -->
              </div>
              <!-- /row -->
              <div
                v-for="(recon, index) in reconList"
                :key="index"
                class="VdTabContentDetail">
                <div class="row">
                  <div class="col-lg-5">
                    <p>
                      <span>{{ recon.panel_name }} </span>
                    </p>
                  </div>
                  <!-- /col -->
                  <div class="col-lg-7">
                    <p>{{ recon.recon }}</p>
                  </div>
                  <!-- /col -->
                </div>
                <!-- /row -->
                <div class="row">
                  <div class="col-12">
                    <img
                      v-if="recon.damage_thumb"
                      :src="recon.damage_thumb"
                      alt="Recon photo"
                      class="img-fluid mx-auto d-block">
                  </div>
                  <!-- /col -->
                </div>
              </div>
              <!-- /VdTabContentDetail -->
            </div>
            <!-- /VdTabContent -->
          </div>
        </div><!-- /col -->
      </div><!-- /row -->
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleSpecs",
  props: {
    attributes: {
      type: Object,
      required: true
    },
    sellerAddress: {
      type: Object,
      required: true
    },
    seller: {
      type: Object,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },
    stockTake: {
      type: Object,
      required: true
    },
    reconList: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    storageLocation() {
      if (this.stockTake.lat) {
        return (
          "https://www.google.com/maps/search/?api=1&query=" +
          this.stockTake.lat +
          "," +
          this.stockTake.long
        );
      }
      return false;
    },
    vehicleDamageImage() {
      return (
        "https://img.overtake.co.za/dealersonline/damage_gif/" +
        this.vehicle.vehicle_id +
        ".gif"
      );
    }
  }
};
</script>

<style scoped>
  .DetailsPage .VdTabContent p span {
    font-weight: 600 !important;
  }
.GeneralListing--LtoR {
  font-size: 12px;
}
</style>
