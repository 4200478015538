<template>
  <section class="Main__TopNav">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
        <button
          class="navbar-toggler custom-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#auctionNavbarToggler"
          aria-controls="auctionNavbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon" />
        </button>
        <router-link
          data-id="link_header_header nav"
          data-text="home"
          to="/">
          <picture class="Obj-Fit__NavLogo">
            <source
              media="(min-width: 1440px)"
              srcset="@/assets/img/navlogo--dsk.png">
            <img
              src="@/assets/img/navlogo.png"
              alt="Standard Bank Logo">
          </picture>
        </router-link>
        <div
          id="auctionNavbarToggler"
          class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto NavBarNav">
            <li class="nav-item nav-item--Home">
              <router-link
                data-id="link_header_header nav"
                data-text="rent a car"
                to="/rent"
                class="nav-link">
                <span>Rent-a-car</span>
              </router-link>
            </li>
            <li class="nav-item nav-item--Gallery">
              <router-link
                data-id="link_header_header nav"
                data-text="sell a car"
                to="/sell"
                class="nav-link">
                <span>Sell-a-car</span>
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav NavBarTools">
            <li class="nav-item nav-item--Login">
              <router-link
                v-if="!isLoggedIn"
                data-id="link_header_header nav"
                data-text="login"
                to="/login"
                class="nav-link">
                <img
                  src="@/assets/img/login.png"
                  alt="Login">
                <span style="font-size: 12px;padding-left: 7px">LOG-IN</span>
              </router-link>
            </li>
            <li class="nav-item nav-item--Watchlist dropdown d-none d-lg-block">
              <router-link
                v-if="!isLoggedIn"
                data-id="link_header_header nav"
                data-text="signup"
                to="/register"
                class="nav-link">
                <img
                  src="@/assets/img/signup.png"
                  alt="Search">
                <span style="font-size: 12px;padding-left: 7px">SIGN-UP</span>
              </router-link>
            </li>
            <li class="nav-item nav-item--Login">
              <router-link
                v-if="isLoggedIn"
                data-id="link_header_header nav"
                data-text="logout"
                to="/logout"
                class="nav-link"
                @click.native="logout">
                <img
                  src="@/assets/img/login.png"
                  style="transform: scaleX(-1);"
                  alt="Login">
                <span style="font-size: 12px;padding-left: 7px">LOG-OUT</span>
              </router-link>
            </li>
            <li class="nav-item nav-item--Help dropdown">
              <a
                id="helpMenu"
                data-id="link_header_header nav"
                data-text="help"
                class="nav-link LastLinkBdR dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="position: relative;">
                <img
                  src="@/assets/img/help.png"
                  alt="Help">
                <span
                  style="border-bottom-color: transparent; font-size: 12px;padding-left: 7px">Help</span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="helpMenu">
                <router-link
                  data-id="link_header_header nav"
                  data-text="help | legal stuff"
                  to="/terms#rent"
                  class="dropdown-item">
                  Legal stuff
                </router-link>
                <router-link
                  data-id="link_header_header nav"
                  data-text="help | faq"
                  to="/faq#rent"
                  class="dropdown-item">
                  FAQ
                </router-link>
                <router-link
                  data-id="link_header_header nav"
                  data-text="help | contact us"
                  to="/contact"
                  class="dropdown-item">
                  Contact us
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /container -->
    </nav>
  </section>
  <!-- /TopNav -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isAuth: null
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getAutUserDisplayName", "getAuthUserId"])
  },
  mounted() {
    this.$nextTick(function() {
      $('nav .navbar-toggler').on('click', function(e){
        e.preventDefault();
        if($('nav .navbar-collapse').hasClass('collapse')){
          $("nav .navbar-collapse").collapse('hide');
        }
      });
    });
  },
  methods: {
    logout() {
      this.$http
        .delete("logout")
        .then(() => {
          this.$auth.logout();
        })
        .catch(() => {
          this.$auth.logout();
          return Promise.resolve(true);
        });
    }
  }
};
</script>

<style scoped>
.nav-link.active {
  font-weight: 600 !important;
}
</style>
