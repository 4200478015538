<template>
  <layout />
</template>

<script>
import Layout from "./views/layouts/Layout.vue";

export default {
  name: "App",
  components: {
    Layout
  }
};
</script>

<style></style>
