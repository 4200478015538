<template>
  <div>
    <desktop-slide />
    <tablet-slide />
    <head-start-info />
    <recently-added />
    <how-it-works />
  </div>
</template>

<script>
import DesktopSlide from "../elements/DesktopSlide";
import TabletSlide from "../elements/TabletSlide";
import HeadStartInfo from "../elements/HeadStartInfo";
import RecentlyAdded from "../elements/RecentlyAdded";
import HowItWorks from "../elements/HowItWorks";
export default {
  name: "Index",
  components: {
    HowItWorks,
    RecentlyAdded,
    HeadStartInfo,
    TabletSlide,
    DesktopSlide
  },
  mounted() {
    // eslint-disable-next-line no-undef
    dataLayer.pageCategory = "Home";
    // eslint-disable-next-line no-undef
    dataLayer.pageName = "home";
  },
};
</script>

<style scoped></style>
