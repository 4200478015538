import { render, staticRenderFns } from "./HeadStartInfo.vue?vue&type=template&id=75f1dfbe&scoped=true&"
import script from "./HeadStartInfo.vue?vue&type=script&lang=js&"
export * from "./HeadStartInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f1dfbe",
  null
  
)

export default component.exports