<template>
  <div
    v-show="pagination.count"
    class="FindResultPagination">
    <nav aria-label="Vehicle result navigation">
      <ul class="pagination justify-content-center justify-content-md-end">
        <li
          :class="PreviousDisabled"
          class="page-item">
          <span
            class="page-link"
            @click.prevent="previousPage()">Prev</span>
        </li>
        <li
          v-for="page in pagesNumber"
          :key="page"
          :class="{'active': page == pagination.current_page}"
          class="page-item">
          <a
            href="javascript:void(0)"
            class="page-link"
            @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li
          :class="NextDisabled"
          class="page-item">
          <a
            class="page-link"
            href="#"
            @click.prevent="nextPage()">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 4
    }
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.total_pages) {
        return [];
      }

      let from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      let to = from + this.offset * 2;
      if (to >= this.pagination.total_pages) {
        to = this.pagination.total_pages;
      }

      let pagesArray = [];
      for (let page = from; page <= to; page++) {
        pagesArray.push(page);
      }
      return pagesArray;
    },
    PreviousDisabled() {
      if (this.pagination.current_page === 1) return "disabled";
      else return "";
    },
    NextDisabled() {
      if (this.pagination.current_page === this.pagination.total_pages)
        return "disabled";
      else return "";
    }
  },
  methods: {
    changePage(page) {
      this.pagination.current_page = page;
      this.$emit("paginate");
    },
    previousPage() {
      if (this.pagination.current_page !== 1)
        this.changePage(this.pagination.current_page - 1);
    },
    nextPage() {
      if (this.pagination.current_page !== this.pagination.total_pages)
        this.changePage(this.pagination.current_page + 1);
    }
  }
};
</script>
