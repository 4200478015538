<template>
  <div>
    <div class="CarslGetStartedListDesktop">
      <div
        id="carslGetStarted"
        class="carousel slide"
        data-ride="carousel">
        <div
          class="container bottomheader2 d-flex flex-column align-items-center">
          <div class="row slider_header_mobile ">
            <div class="col-xs-12">
              <h1 class="herobottom">
                How it works
              </h1>
            </div>
          </div>

          <ul
            class="carousel-indicators CarslGetStartedList CarslGetStartedList--Desktop">
            <li
              data-target="#carslGetStarted"
              data-slide-to="0"
              class="active">
              <img
                class="slidenumber1"
                src="@/assets/img/number.png"
                style="width: 32px; height: 32px"><span>Register</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStarted"
              data-slide-to="1">
              <img
                class="slidenumber2"
                src="@/assets/img/number2.png"
                style="width: 32px; height: 32px"><span>Find your Car</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStarted"
              data-slide-to="2">
              <img
                class="slidenumber3"
                src="@/assets/img/number3.png"
                style="width: 32px; height: 32px"><span>Select Payment Option</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStarted"
              data-slide-to="3">
              <img
                class="slidenumber4"
                src="@/assets/img/number4.png"
                style="width: 32px; height: 32px"><span>Application</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStarted"
              data-slide-to="4">
              <img
                class="slidenumber5"
                src="@/assets/img/number5.png"
                style="width: 32px; height: 32px"><span>Sign Contract</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStarted"
              data-slide-to="5">
              <img
                class="slidenumber6"
                src="@/assets/img/number6.png"
                style="width: 32px; height: 32px"><span>Take Delivery!</span>
            </li>
          </ul>
          <!-- /CarslGetStartedList--Desktop -->

          <div class="carousel-inner d-lg-flex">
            <section class="carousel-item active" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />
          </div>

          <section class="bottomheader">
            <div class="container">
              <div class="hero_bottom_strip center">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      v-show="!isLoggedIn"
                      class="row some_class">
                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <router-link
                          data-id="link_content_content links"
                          data-text="how it works | login button click"
                          to="/login"
                          class="btn loginbtn btn-primary home car-search-btn">
                          LOG IN
                        </router-link>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <router-link
                          data-id="link_content_content links"
                          data-text="how it works | register button click"
                          to="/register"
                          class="btn signupbtn btn-primary home car-search-btn">
                          REGISTER
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="CarslGetStartedList--Mobile">
      <div
        id="carslGetStartedmobile"
        class="carousel slide"
        data-ride="carousel">
        <div
          class="container bottomheader2 d-flex flex-column align-items-center">
          <div class="row slider_header_mobile ">
            <div class="col-xs-12">
              <h1 class="herobottom">
                How it works
              </h1>
            </div>
          </div>

          <ul class="carousel-indicators mobile_list CarslGetStartedList">
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="0"
              class="active">
              <img
                class="slidenumber1"
                src="@/assets/img/number.png"
                style="width: 32px; height: 32px"><span>Register</span>&nbsp;&nbsp;<span
                  class="chev_span ">&gt;</span>&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="1">
              <img
                class="slidenumber2"
                src="@/assets/img/number2.png"
                style="width: 32px; height: 32px"><span>Find your Car</span>&nbsp;&nbsp;<span
                  class="chev_span ">&gt;</span>&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="2">
              <img
                class="slidenumber3"
                src="@/assets/img/number3.png"
                style="width: 32px; height: 32px"><span class="longspan">Payment Options</span>&nbsp;&nbsp;<span
                  class="chev_span long">&gt;</span>&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="3">
              <img
                class="slidenumber4"
                src="@/assets/img/number4.png"
                style="width: 32px; height: 32px"><span>Application</span>&nbsp;&nbsp;<span
                  class="chev_span ">&gt;</span>&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="4">
              <img
                class="slidenumber5"
                src="@/assets/img/number5.png"
                style="width: 32px; height: 32px"><span>Sign Contract</span>&nbsp;&nbsp;<span
                  class="chev_span ">&gt;</span>&nbsp;&nbsp;
            </li>
            <li
              data-target="#carslGetStartedmobile"
              data-slide-to="5">
              <img
                class="slidenumber6"
                src="@/assets/img/number6.png"
                style="width: 32px; height: 32px"><span>Take Delivery!</span>
            </li>
          </ul>
          <!-- /CarslGetStartedList--Mobile -->

          <div class="carousel-inner d-lg-flex">
            <section class="carousel-item active" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />

            <section class="carousel-item" />
          </div>

          <section class="bottomheader">
            <div class="container">
              <div class="hero_bottom_strip center">
                <div class="row">
                  <div
                    v-show="!isLoggedIn"
                    class="col-lg-12">
                    <div class="row some_class">
                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <router-link
                          data-id="link_content_content links"
                          data-text="how it works | login button click"
                          to="/login"
                          class="btn loginbtn btn-primary home car-search-btn">
                          LOG IN
                        </router-link>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <router-link
                          data-id="link_content_content links"
                          data-text="how it works | register button click"
                          to="/register"
                          class="btn signupbtn btn-primary home car-search-btn">
                          REGISTER
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HowItWorks",
  computed: {
    ...mapGetters(["isLoggedIn"])
  }
};
</script>

<style scoped></style>
