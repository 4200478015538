<template>
  <div>
    <footer class="MainFooter mobile_none">
      <div class="container">
        <div class="FooterInnerBox">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <section class="FooterLinkBox">
                <h1>Get in Touch</h1>
                <p>
                  support@mobile.standardbank.co.za Dial 135 from your Standard
                  Bank SIM 0801 212 577 from a mobile or&nbsp;landline
                </p>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-md-2 tablet_none">
              <section class="FooterLinkBox">
                <h1>Useful links</h1>
                <ul>
                  <li>
                    <router-link
                      to="/rent"
                      data-id="footer_nav_link"
                      data-text="rent a car">
                      Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/sell"
                      data-id="footer_nav_link"
                      data-text="sell a car">
                      Sell/Trade-in a car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-md-3 tablet_none">
              <section class="FooterLinkBox">
                <h1>Help</h1>
                <ul>
                  <li>
                    <router-link
                      to="/faq#rent"
                      data-id="footer_nav_link"
                      data-text="help and faq">
                      Help & FAQ
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/terms#rent"
                      data-id="footer_nav_link"
                      data-text="terms and conditions">
                      Terms & Conditions
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div
              v-show="!isLoggedIn"
              class="col-md-2 tablet_none">
              <section class="FooterLinkBox">
                <h1>Account info</h1>
                <ul>
                  <li>
                    <router-link
                      to="/login"
                      data-id="footer_nav_link"
                      data-text="login">
                      Login to My Account
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-md-6 col-lg-2">
              <section class="FooterLinkBox">
                <h1>Get to Know Us</h1>
                <ul>
                  <li><a href="https://www.standardbank.com/sbg/standard-bank-group/who-we-are/our-group-at-a-glance">Who are we</a></li>
                  <li><a href="https://www.standardbank.co.za">Standard Bank Group</a></li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
          </div>
          <!-- /row -->
        </div>
        <!-- /FooterInnerBox -->
      </div>
      <!-- /container -->
      <section class="lowerfooter">
        <div class="container">
          <div class="footertext col-lg-12">
            <span
              style="color:#fff">Standard Bank is a licensed financial services provider in terms
              of the Financial Advisory and Intermediary Services Act and a
              registered credit provider in terms of the National Credit Act,
              registration number NCRCP15</span>
          </div>
          <div class="footerlinks">
            <table>
              <tbody>
                <tr>
                  <td class="footlink">
                    <a
                      href="https://www.standardbank.co.za/southafrica/personal/about-us/legal"><span
                      style="color:#fff; margin-bottom: 48px">Legal</span></a>
                  </td>
                  <td class="footlink middelink">
                    <a
                      href="https://www.standardbank.co.za/southafrica/personal/about-us/regulatory"><span
                      style="color:#fff; margin-bottom: 48px">Regulatory</span></a>
                  </td>
                  <td class="footlink">
                    <a
                      href="https://www.standardbank.co.za/southafrica/personal/about-us/terms-and-conditions"><span
                      style="color:#fff; margin-bottom: 48px">Terms & Conditions</span></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /row -->
      </section>
    </footer>

    <footer class="MainFooter desktop_none">
      <div class="container">
        <div class="FooterInnerBox">
          <div class="row">
            <div class="col-sm-12">
              <section class="FooterLinkBox">
                <div class="footheadwidth">
                  <h1
                    data-toggle="collapse"
                    data-target="#one">
                    Get in Touch <i class="fas fa-chevron-down footerdown" />
                  </h1>
                  <hr class="hbline">
                </div>
                <p
                  id="one"
                  class="collapse">
                  support@mobile.standardbank.co.za Dial 135 from your Standard
                  Bank SIM 0801 212 577 from a mobile or&nbsp;landline
                </p>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-sm-12">
              <section class="FooterLinkBox">
                <div class="footheadwidth">
                  <h1
                    data-toggle="collapse"
                    data-target="#two">
                    Useful links <i class="fas fa-chevron-down footerdown" />
                  </h1>
                  <hr class="hbline">
                </div>
                <ul
                  id="two"
                  class="collapse">
                  <li>
                    <router-link
                      to="/rent"
                      data-id="footer_nav_link"
                      data-text="rent a car">
                      Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/sell"
                      data-id="footer_nav_link"
                      data-text="sell a car">
                      Sell/Trade-in a car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-sm-12">
              <section class="FooterLinkBox">
                <div class="footheadwidth">
                  <h1
                    data-toggle="collapse"
                    data-target="#three">
                    Help <i class="fas fa-chevron-down footerdown" />
                  </h1>
                  <hr class="hbline">
                </div>
                <ul
                  id="three"
                  class="collapse">
                  <li>
                    <router-link
                      to="/faq#rent"
                      data-id="footer_nav_link"
                      data-text="help and faq">
                      Help & FAQ
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/terms#rent"
                      data-id="footer_nav_link"
                      data-text="terms and conditions">
                      Terms & Conditions
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
            <div
              v-show="!isLoggedIn"
              class="col-sm-12">
              <section class="FooterLinkBox">
                <div class="footheadwidth">
                  <h1
                    data-toggle="collapse"
                    data-target="#four">
                    Account info <i class="fas fa-chevron-down footerdown" />
                  </h1>
                  <hr class="hbline">
                </div>
                <ul
                  id="four"
                  class="collapse">
                  <li>
                    <router-link
                      to="/login"
                      data-id="footer_nav_link"
                      data-text="login">
                      Login to My Account
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-sm-12">
              <section class="FooterLinkBox">
                <div class="footheadwidth">
                  <h1
                    data-toggle="collapse"
                    data-target="#five">
                    Get to Know Us
                    <i class="fas fa-chevron-down footerdown" />
                  </h1>
                  <hr class="hbline">
                </div>
                <ul
                  id="five"
                  class="collapse">
                  <li><a href="https://www.standardbank.com/sbg/standard-bank-group/who-we-are/our-group-at-a-glance">Who are we</a></li>
                  <li><a href="https://www.standardbank.co.za">Standard Bank Group</a></li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
          </div>
          <!-- /row -->
        </div>
        <!-- /FooterInnerBox -->
      </div>
      <!-- /container -->
      <section class="lowerfooter">
        <div class="container">
          <div class="footertext col-lg-12">
            <span
              style="color:#fff">Standard Bank is a licensed financial services provider in terms
              of the Financial Advisory and Intermediary Services Act and a
              registered credit provider in terms of the National Credit Act,
              registration number NCRCP15</span>
          </div>
          <div class="footerlinks_mobile">
            <a
              class="mobile_link"
              href="https://www.standardbank.co.za/southafrica/personal/about-us/legal"><span style="color:#fff; margin-bottom: 48px">Legal</span></a>

            <a
              class="mobile_link padding_link"
              href="https://www.standardbank.co.za/southafrica/personal/about-us/regulatory"><span
                style="color:#fff; margin-bottom: 48px">Regulatory</span></a>

            <a
              class="mobile_link"
              href="https://www.standardbank.co.za/southafrica/personal/about-us/terms-and-conditions"><span
                style="color:#fff; margin-bottom: 48px">Terms&nbsp; & &nbsp;Conditions</span></a>
          </div>
        </div>
        <!-- /row -->
      </section>
    </footer>
    <cookie-law />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  name: "SiteFooter",
  components: {
    CookieLaw
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  mounted() {
    this.$nextTick(function() {
      $('h1').on('click', function(e){
        e.preventDefault();
        if($($(this).data('target')).hasClass('collapse')){
          $($(this).data('target')).collapse('hide');
        }
      });
    });
  },
};
</script>

<style scoped></style>
