<template>
  <section class="GeneralPage">
    <div
      class="container"
      style="margin-bottom: 48px;">
      <h1 class="title">
        Activation
      </h1>
      <hr>
      <div
        v-if="activated"
        class="alert alert-success">
        <strong>Success!</strong> Your account has been activated. You may now
        login.
      </div>
      <div
        v-if="!activated"
        class="alert alert-danger">
        <strong>Error!</strong> Account has not been activated. It is possible
        that your token is invalid.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AccountActivation",
  data() {
    return {
      activated: false
    };
  },
  created() {
    let token = this.$route.params.token;

    let data = {
      token: token
    };

    this.$http
      .post("bankds/graduate/user/activate", data)
      .then(response => {
        if (response) {
          if (response.data.data.success) {
            this.activated = true;
          }
        }
      })
      .catch(() => {
        return Promise.resolve(true);
      });
  }
};
</script>

<style></style>
