<template>
  <form
    id=" "
    autocomplete="off"
    data-frmcontainer="registration graduate car finance"
    @submit.prevent>
    <section class="sellBar">
      <div
        v-if="loadingWizard"
        class="loader" />
      <div
        class="container"
        style="margin-top: 48px; margin-bottom: 48px;">
        <div class="col-sm-12">
          <h1 class="sellcarHead">
            Register
          </h1>
        </div>

        <div
          style="max-width: 900px;margin-right: auto;margin-left: auto; margin-top: 48px">
          <div style="text-align: center;">
            <h1 class="login_title">
              Registration
            </h1>

            <h3
              style="font-size: 16px; font-weight: 400; color:#ffffff;margin-bottom: 12px;">
              Register to find your perfect rental deal
            </h3>
            <!-- end row -->

            <h3 style="font-size: 12px; font-weight: 100;color:#ffffff">
              * Denotes mandatory field. ** Atleast one telephone number is
              required.
            </h3>
          </div>

          <h2 class=" decorated">
            <span>CREATE AN ACCOUNT</span>
          </h2>

          <section>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 gForm">
                <div
                  :class="errors.has('accountFirstName') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    v-model="form.account_first_name"
                    v-validate="'required'"
                    data-vv-as="Account First Name"
                    name="accountFirstName"
                    type="text"
                    placeholder="* Your First Name"
                    class="form-control input-lg">
                </div>
                <span
                  v-show="errors.has('accountFirstName')"
                  class="help-block">{{ errors.first("accountFirstName") }}</span>

                <div
                  :class="errors.has('accountLastName') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    v-model="form.account_last_name"
                    v-validate="'required'"
                    data-vv-as="Account Last Name"
                    name="accountLastName"
                    type="text"
                    placeholder="* Your Last Name"
                    class="form-control input-lg">
                </div>
                <span
                  v-show="errors.has('accountLastName')"
                  class="help-block">{{
                    errors.first("accountLastName")
                  }}</span>

                <div
                  :class="errors.has('accountCellNo') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    v-model="form.account_cell_no"
                    v-validate="{
                      rules: {
                        required: true,
                        numeric: true,
                        regex: /^0[687][0123456789]((\d{7})|( |-)((\d{3}))( |-)(\d{4})|( |-)(\d{7}))$/
                      }
                    }"
                    data-vv-as="Account Cell Number"
                    name="accountCellNo"
                    type="text"
                    placeholder="* Your Cell No"
                    class="form-control input-lg">
                </div>
                <span
                  v-show="errors.has('accountCellNo')"
                  class="help-block">{{
                    errors.first("accountCellNo")
                  }}</span>

                <div
                  :class="errors.has('accountEmailAddress') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    v-model="form.account_email_address"
                    v-validate="'required|email'"
                    data-vv-as="Account Email Address"
                    name="accountEmailAddress"
                    type="text"
                    placeholder="* Your Email Address (Username)"
                    class="form-control input-lg"
                    autocomplete="new-password">
                </div>
                <span
                  v-show="errors.has('accountEmailAddress')"
                  class="help-block">{{ errors.first("accountEmailAddress") }}</span>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 gForm bottomfrm">
                <div
                  :class="errors.has('accountPassword') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    ref="accountPassword"
                    v-model="form.account_password"
                    v-validate="'required|secure_password'"
                    data-vv-as="Account Password"
                    name="accountPassword"
                    type="password"
                    placeholder="* Password"
                    class="form-control input-lg"
                    autocomplete="new-password">
                </div>
                <span
                  v-show="errors.has('accountPassword')"
                  class="help-block">{{
                    errors.first("accountPassword")
                  }}</span>
                <div
                  :class="errors.has('accountPasswordConfirm') ? 'has-error' : ''"
                  class="form-group round_form">
                  <input
                    v-model="form.account_password_confirmation"
                    v-validate="'required|confirmed:accountPassword'"
                    data-vv-as="Account Password Confirmation"
                    name="accountPasswordConfirm"
                    type="password"
                    placeholder="* Password Confirmation"
                    class="form-control input-lg"
                    autocomplete="new-password">
                </div>
                <span
                  v-show="errors.has('accountPasswordConfirm')"
                  class="help-block">{{ errors.first("accountPasswordConfirm") }}</span>
                <div
                  :class="errors.has('addressProvince') ? 'has-error' : ''"
                  class="form-group round_form">
                  <select
                    v-model="form.address_province"
                    v-validate="'required'"
                    placeholder="* Province"
                    data-vv-as="Province"
                    name="addressProvince"
                    class="form-control custom-select searchbox">
                    <option
                      value=""
                      selected>
                      * Select Province
                    </option>
                    <option value="BOT">
                      Botswana
                    </option>
                    <option value="EC">
                      Eastern Cape
                    </option>
                    <option value="FS">
                      Freestate
                    </option>
                    <option value="GT">
                      Gauteng
                    </option>
                    <option value="KZN">
                      KwaZulu-Natal
                    </option>
                    <option value="LMP">
                      Limpopo
                    </option>
                    <option value="MP">
                      Mpumalanga
                    </option>
                    <option value="NAM">
                      Namibia
                    </option>
                    <option value="NC">
                      Northern Cape
                    </option>
                    <option value="NW">
                      North West
                    </option>
                    <option value="SWA">
                      Swaziland
                    </option>
                    <option value="WC">
                      Western Cape
                    </option>
                  </select>
                </div>
                <span
                  v-show="errors.has('addressProvince')"
                  class="help-block">{{
                    errors.first("addressProvince")
                  }}</span>

                <div class="form-group">
                  <div class="row checkboxreg">
                    <div class="regcheckbox custom-control custom-checkbox2 ">
                      <input
                        id="AgreeId11"
                        v-model="form.agree_t_and_c"
                        v-validate="{ rules: { required: true } }"
                        name="accountTerms"
                        data-vv-as="Site Terms And Conditions"
                        type="checkbox"
                        class="custom-control-input">
                      <label
                        class="custom-control-label termstext"
                        for="AgreeId11">Agree to Terms &amp; Conditions
                        <router-link
                          to="/terms#rent"
                          target="_blank">
                          <a>View Terms</a>
                        </router-link></label>
                    </div>
                  </div>
                  <span
                    v-show="errors.has('accountTerms')"
                    class="help-block">{{
                      errors.first("accountTerms")
                    }}</span>
                </div>

                <div class="form-group">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="sitekey"
                    @verify="onVerify"
                    @expired="onExpired">
                    <button
                      :disabled="status === 'submitting'"
                      class="btn btn-primary btn_reg_new"
                      data-id="link_content_content link"
                      data-text="register button click"
                      @click="send">
                      Register
                    </button>
                  </vue-recaptcha>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    <!-- /container -->
    </section>
  </form>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import swal from "sweetalert2";

export default {
  name: "Register",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      form: {
        site_url: process.env.VUE_APP_DOMAIN,
        entity_type: 2,
        address_line1: "NA",
        address_line2: "NA",
        address_province: "",
        address_city: "NA",
        billing_first_name: "",
        billing_last_name: "",
        billing_email_address: "",
        billing_tel_no: "NA",
        account_tel_no: "NA",
        account_first_name: "",
        account_last_name: "",
        account_cell_no: "",
        account_email_address: "",
        account_password: "",
        account_password_confirmation: "",
        agree_t_and_c: false,
        verified_captcha_code: ""
      },
      loadingWizard: false,
      sitekey: "6LcC82gUAAAAAD8dTfzPZm4_YEr0RFZbsw8yMyFb",
      status: ""
    };
  },
  watch: {
    "form.account_first_name": function(newVal) {
      this.form.billing_first_name = newVal;
    },
    "form.account_last_name": function(newVal) {
      this.form.billing_last_name = newVal;
    },
    "form.account_email_address": function(newVal) {
      this.form.billing_email_address = newVal;
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    dataLayer.pageCategory = "Signup";
    // eslint-disable-next-line no-undef
    dataLayer.pageName = "registration:application:create an account";

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    onVerify: function(response) {
      const self = this;
      this.setLoading(true);
      self.status = "submitting";
      self.$refs.recaptcha.reset();
      self.status = "";
      this.setLoading(false);
      this.form.verified_captcha_code = response;
      this.register();
    },
    onExpired: function() {
      swal("Expired", "The recapcha has expired.", "error");
    },
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    send() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    register() {
      this.setLoading(true);

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http
            .post("bankds/graduate/register", this.form)
            .then(response => {
              this.setLoading(false);
              if (response) {
                // eslint-disable-next-line no-undef
                dataLayer.formStatus = 'complete';
                // eslint-disable-next-line no-undef
                dataLayer.formisSubmitted = true;
                // eslint-disable-next-line no-undef
                _satellite.track("globalFormComplete");


                swal(
                  "Almost there",
                  "You are now registered. An email has been sent to you for verification..",
                  "success"
                );
                this.$router.push("/login");
              }
            })
            .catch(() => {
              // console.log('sending error')
              // // eslint-disable-next-line no-undef
              // dataLayer.siteErrorCode = '500 | user exists | '+ 'service:register';
              // // eslint-disable-next-line no-undef
              // _satellite.track('sendSiteErrorCode');

              this.setLoading(false);
            });
        } else {
          this.setLoading(false);
        }
      });
    }
  }
};
</script>

<style scoped>
.termstext a:hover {
  color:black;
}
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .btn {
    min-width: 150px !important;
  }
}
@media (min-width: 576px) {
  .btn {
    min-width: 180px !important;
  }
}
</style>
