<template>
  <section class="Desktop_slides">
    <div
      id="desktopslide"
      class="carousel slide"
      data-ride="carousel">
      <!-- Indicators -->
      <ul class="carousel-indicators homeslideindicate">
        <li
          data-target="#desktopslide"
          data-slide-to="0"
          class="caraline active" />
        <li
          data-target="#desktopslide"
          data-slide-to="1"
          class="caraline" />
        <li
          data-target="#desktopslide"
          data-slide-to="2"
          class="caraline" />
        <li
          data-target="#desktopslide"
          data-slide-to="3"
          class="caraline" />
        <li
          data-target="#desktopslide"
          data-slide-to="4"
          class="caraline" />
      </ul>

      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div
              id="sliderbackgroundblue"
              class="col-md-5">
              <div class="carouselcontainer">
                <h3 class="carouselheading">
                  Vehicle finance for young professionals
                </h3>
                <p class="carouseltext">
                  Rent a vehicle from us at competitive interest rates and low
                  monthly instalments and get moving.
                </p>
                <a
                  data-id="link_banner"
                  data-text="vehicle finance for young professionals | tell me more button click"
                  class="carouselbtn"
                  style="cursor:pointer;"
                  @click="tellMeMore()">TELL ME MORE</a>
              </div>
            </div>

            <div
              id="slideronebackground"
              class="col-md-7" />
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div
              id="sliderbackgroundblue"
              class="col-md-5">
              <div class="carouselcontainer">
                <h3 class="carouselheading">
                  Options to buy your rental car
                </h3>
                <p class="carouseltext">
                  You can choose to buy the rental car before your contract
                  expires or when your rental term comes to an end.
                </p>
                <a
                  data-id="link_banner"
                  data-text="options to buy your rental car | tell me more button click"
                  class="carouselbtn"
                  style="cursor:pointer;"
                  @click="tellMeMore()">TELL ME MORE</a>
              </div>
            </div>

            <div
              id="slidertwobackground"
              class="col-md-7" />
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div
              id="sliderbackgroundblue"
              class="col-md-5">
              <div class="carouselcontainer">
                <h3 class="carouselheading">
                  Vehicles that are less than a year old
                </h3>
                <p class="carouseltext">
                  Choose from a variety of vehicles that are almost new - from
                  Volkswagen to Ford, we’ve got the perfect car for you.
                </p>
                <a
                  data-id="link_banner"
                  data-text="vehicles that are less than a year old | tell me more button click"
                  class="carouselbtn"
                  style="cursor:pointer;"
                  @click="tellMeMore()">TELL ME MORE</a>
              </div>
            </div>

            <div
              id="sliderthreebackground"
              class="col-md-7" />
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div
              id="sliderbackgroundblue"
              class="col-md-5">
              <div class="carouselcontainer">
                <h3 class="carouselheading">
                  Maintenance and service plan included
                </h3>
                <p class="carouseltext">
                  Stay on the road and keep going with the remaining term of the
                  rental car’s maintenance or service plan.
                </p>
                <a
                  data-id="link_banner"
                  data-text="maintenance and service plan included | tell me more button click"
                  class="carouselbtn"
                  style="cursor:pointer;"
                  @click="tellMeMore()">TELL ME MORE</a>
              </div>
            </div>

            <div
              id="sliderfourbackground"
              class="col-md-7" />
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div
              id="sliderbackgroundblue"
              class="col-md-5">
              <div class="carouselcontainer">
                <h3 class="carouselheading">
                  Need an affordable car?
                </h3>
                <p class="carouseltext">
                  Rent our low mileage vehicles without having to worry about a
                  hefty deposit or balloon payment.
                </p>
                <a
                  data-id="link_banner"
                  data-text="need an affordable car | tell me more button click"
                  class="carouselbtn"
                  style="cursor:pointer;"
                  @click="tellMeMore()">TELL ME MORE</a>
              </div>
            </div>

            <div
              id="sliderfivebackground"
              class="col-md-7" />
          </div>
        </div>
      </div>

      <!-- Left and right controls -->
      <a
        class="carousel-control-prev"
        href="#desktopslide"
        data-slide="prev">
        <span class="carousel-control-prev-icon" />
      </a>
      <a
        class="carousel-control-next"
        href="#desktopslide"
        data-slide="next">
        <span class="carousel-control-next-icon" />
      </a>
    </div>
  </section>
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name: "DesktopSlide",
  methods: {
    tellMeMore: function() {
      ScrollTo.scrollTo('#carslGetStarted');
    }
  }
};
</script>

<style scoped></style>
