import { render, staticRenderFns } from "./DesktopSlide.vue?vue&type=template&id=6ba17f97&scoped=true&"
import script from "./DesktopSlide.vue?vue&type=script&lang=js&"
export * from "./DesktopSlide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba17f97",
  null
  
)

export default component.exports