import Vue from "vue";
import { store } from "../store/index";
import VueRouter from "vue-router";

import SiteIndex from "../views/home/Index.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/register/Register.vue";
import ForgotPassword from "../views/auth/Forgot";
import ResetPassword from "../views/auth/ResetPassword";
import AccountActivate from "../views/auth/Activate";

import RentIndex from "../views/rent/Index.vue";
import SellIndex from "../views/sell/Index.vue";

import ViewRentVehicle from "../views/rent/View.vue";

import Error404 from "../views/site/Error404.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/index" },
  { path: "/logout", redirect: "/login" },
  {
    path: "/index",
    name: "home",
    component: SiteIndex,
    meta: {
      breadcrumb: "Home",
      displayBreadCrumb: false
    }
  },
  {
    path: "/login",
    component: Login,
    meta: {
      breadcrumb: {
        label: "Login",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/register",
    component: Register,
    meta: {
      breadcrumb: {
        label: "Register",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/forgot",
    component: ForgotPassword,
    meta: {
      breadcrumb: {
        label: "Forgot Password",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/password/reset/:token",
    component: ResetPassword,
    meta: {
      breadcrumb: {
        label: "Reset Password",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/rent",
    name: "rentgrid",
    component: RentIndex,
    meta: {
      breadcrumb: {
        label: "Rent-A-Car",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/rent/view/:slug/:vehicle_id",
    name: "rentview",
    component: ViewRentVehicle,
    props: true,
    meta: {
      displayBreadCrumb: true
    }
  },
  {
    path: "/sell",
    name: "sell",
    component: SellIndex,
    meta: {
      breadcrumb: {
        label: "Sell/Trade-In",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/site/Terms"),
    meta: {
      breadcrumb: {
        label: "Terms and conditions",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/activate/:token",
    component: AccountActivate,
    meta: {
      requiresAuth: false,
      breadcrumb: {
        label: "User Activation",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/site/Faq"),
    meta: {
      breadcrumb: {
        label: "Frequently asked questions",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/contact",
    component: () => import("../views/site/ContactUs"),
    meta: {
      requiresAuth: true,
      breadcrumb: {
        label: "Contact us",
        parent: "home"
      },
      displayBreadCrumb: true
    }
  },
  {
    path: "/notfound",
    component: Error404
  },
  {
    path: "*",
    component: Error404
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.getters.isLoggedIn) {
    next({
      path: "/login"
    });
  } else {
    next();
  }
});

export default router;
