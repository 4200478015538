<template>
  <form
    id=" "
    autocomplete="off"
    data-frmcontainer="login graduate car finance"
    @submit.prevent>
    <section class="sellBar">
      <div
        v-if="loadingWizard"
        class="loader" />
      <div
        class="container"
        style="margin-top: 48px; margin-bottom: 48px;">
        <div class="col-sm-12">
          <h1 class="sellcarHead">
            Login
          </h1>
        </div>

        <div class="container loginblock">
          <div
            class="ContactVdBlock"
            style="max-width: 450px; margin-left: auto;margin-right: auto; margin-top: 48px;">
            <div class="col-md-12">
              <h1 class="login_title">
                Login Now
              </h1>

              <h3
                style="font-size: 16px; font-weight: 100;color: #ffffff;text-align: center;">
                Login to our Website
              </h3>
              <!-- end row -->
              <h2 class="decorated">
                <span>SIGN IN</span>
              </h2>

              <div class="row">
                <div class="col-sm-12 gForm">
                  <div class="form-group round_form">
                    <input
                      v-model="email"
                      v-validate="'required|email'"
                      type="email"
                      data-vv-as="Email"
                      name="username"
                      tabindex="1"
                      class="form-control input-lg"
                      placeholder="Email"
                      autocomplete="new-password">
                  </div>
                  <span
                    v-show="errors.has('username')"
                    class="help-block">{{
                      errors.first("username")
                    }}</span>
                  <div class="form-group round_form">
                    <input
                      v-model="password"
                      v-validate="'required'"
                      type="password"
                      data-vv-as="Password"
                      name="password"
                      tabindex="2"
                      class="form-control input-lg"
                      placeholder="Password"
                      autocomplete="new-password">
                  </div>
                  <span
                    v-show="errors.has('password')"
                    class="help-block">{{
                      errors.first("password")
                    }}</span>
                  <div class="form-group">
                    <router-link
                      to="/forgot"
                      class="forgotpassword">
                      <a>Forgot Password?</a>
                    </router-link>
                  </div>
                  <div class="form-group">
                    <button
                      class="btn btn-primary btn_login_new"
                      @click="login(true)">
                      LOGIN NOW
                    </button>
                  </div>
                </div>
              <!-- end col -->
              </div>
            </div>
          </div>
        </div>
      <!-- end VdBlock -->
      </div>
    <!-- /container -->
    </section>
  </form>
</template>

<script>
import swal from "sweetalert2";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      remember_me: false,
      loadingWizard: false
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    dataLayer.pageCategory = "Login";
    // eslint-disable-next-line no-undef
    dataLayer.pageName = "login:application:sign in";
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    login() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var data = {
            emailaddress: this.email,
            password: this.password,
            remember_me: this.remember_me,
            include: "company,userverification"
          };
          //Get the auth token and expired time
          this.setLoading(true);
          this.$http
            .post("bankds/graduate/auth", data)
            .then(response => {
              this.setLoading(false);
              this.$auth.setToken(response.data.data.token);
              //Sets login to true
              this.$store.dispatch("login", {
                user: response.data.data.user /*user details*/,
                company: response.data.data.company.data /*company details*/,
                userverification:
                  response.data.data.userverification
                    .data /*subscription details*/
              });

              // eslint-disable-next-line no-undef
              dataLayer.formStatus = 'complete';
              // eslint-disable-next-line no-undef
              dataLayer.formisSubmitted = true;
              // eslint-disable-next-line no-undef
              _satellite.track("globalFormComplete");


              this.$router.push("/index");
            })
            .catch(error => {
              this.setLoading(false);


              if (error.response) {
                if (error.response.status == 422) {
                  swal({
                    title: "Resend activation email?",
                    type: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Resend"
                  }).then(
                    function(isConfirm) {
                      if (isConfirm.value !== undefined && isConfirm.value) {
                        const payload = {
                          site_url: process.env.VUE_APP_DOMAIN,
                          email_address: this.email
                        };
                        this.$http
                          .post("bankds/graduate/resendActivationEmail", payload)
                          .then(function() {
                            swal(
                              "Success",
                              "Email has been dispatched.",
                              "success"
                            );
                          })
                          .catch(function(error) {
                            if (error.response && error.response.data) {
                              swal(
                                "Error",
                                error.response.data.data.message,
                                "error"
                              );
                            }
                          });
                      }
                    }.bind(this)
                  );
                } else {
                  swal("Error", error.response.data.message, "error");
                }
              }
            });
        }
      });
    }
  }
};
</script>
