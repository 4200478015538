<template>
  <section>
    <div class="PriceBlock">
      <h1>Available<span class="fcPrice">Rental Rates</span></h1>
    </div>
    <div class="FcBidBox">
      <div
        id="biddingTabContent"
        class="tab-content">
        <div
          id="bid"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="bid-tab">
          <section class="FcBidFormBox">
            <form class="gForm FcBidForm">
              <fieldset disabled="">
                <div
                  v-for="(rate,index) in rates"
                  :key="index"
                  class="form-group">
                  <label for="bidAmount"><img
                    src="@/assets/img/calculatorico.png"
                    alt="Bid Card"
                    style="margin-right: 2px;">{{ rate.months }} Months / {{ rate.annual_kms }} KM</label>
                  <input
                    id="bidAmount"
                    :placeholder="rate.pm_value"
                    type="text"
                    class="form-control">
                </div>
                <div
                  v-if="rates.length == 0"
                  class="form-group">
                  <label for="bidAmount">Not Available</label>
                </div>
              </fieldset>
            </form>
          </section><!-- /FcBidBlck -->
        </div><!-- /bid-tab-content -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name:"RentalRates",
  props: {
    rates: {
      type: Array,
      required: true
    }
  }
}
</script>

<style>

</style>
