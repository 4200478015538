import qs from "query-string";

export default {
  methods: {
    buildUrl(searchObject) {
      const queryObj = {
        page: searchObject.page,
          q: searchObject.q,
          reg_no: searchObject.reg_no,
          make: searchObject.make,
          province: searchObject.province,
          min_price: searchObject.min_price,
          max_price: searchObject.max_price,
          min_year: searchObject.min_year,
          max_year: searchObject.max_year,
          min_mileage: searchObject.min_mileage,
          max_mileage: searchObject.max_mileage
      };

      let querystring = qs.stringify(queryObj, { sort: false })

      let fullPath = `${this.$route.path}?${querystring}`;

      if (this.$route.fullPath !== fullPath) this.$router.push(fullPath)
    }
  }
};
