<template>
  <section v-show="finishedLoading">
    <section v-show="isLoggedIn">
      <section v-if="!myReservedVehicle">
        <button
          data-id="link_content links"
          data-text="rent now button click"
          type="button"
          class="btn btn-primary btn--FullWBtn loginbtnright"
          @click="modalOpen = !modalOpen">
          RENT NOW
        </button>

        <rent-popup
          :open="modalOpen"
          :vehicle="vehicle"
          :rates="rates"
          @reserve="vehicleReserved()"
          @modalClosed="modalClosed()" />
      </section>
      <section
        v-else-if="myReservedVehicle == 1"
        class="PriceBlock">
        <h1>Reserved<span class="fcPrice fcCalender">Congrats!</span></h1>
      </section>
      <section
        v-else-if="myReservedVehicle == 2"
        class="PriceBlock">
        <h1 style="padding-bottom: 24px">
          <span
            class="fcPrice"
            style="padding-bottom: 24px">Oops!</span>You may only reserve
          one vehicle at a time.
        </h1>
      </section>
      <section
        v-else-if="myReservedVehicle == 3"
        class="PriceBlock">
        <h1 style="padding-bottom: 24px">
          <span
            class="fcPrice"
            style="padding-bottom: 24px">Oops!</span>Taken
        </h1>
      </section>
    </section>
    <section v-show="!isLoggedIn">
      <a
        data-id="link_content links"
        data-text="login button click"
        href="#"
        class="btn btn-primary btn--FullWBtn loginbtnright"
        @click="howDoesItWork()">
        LOGIN | SIGN UP
      </a>
    </section>
  </section>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import RentPopup from '../elements/RentModal'

var ScrollTo = require("vue-scrollto");

export default {
  name: "Reservation",
  components: {
    RentPopup
  },
  props: {
    id: {
      default: 'reserve',
      type: String,
      required: false
    },
    vehicle: {
      type: Object,
      required:true
    },
    rates: {
      type:Array,
      required:true
    }
  },
  data() {
    return {
      accept_term: false,
      selectedRate:'',
      finishedLoading: false,
      reservedUserId: null,
      reservedVehicleId: null,
      modalOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getAuthUserId"
    ]),
    myReservedVehicle() {
      if(this.reservedUserId === this.getAuthUserId && this.reservedVehicleId === this.vehicle.id) {
        return 1;
      }else if(this.reservedUserId === this.getAuthUserId){
        return 2;
      }else if(this.reservedVehicleId === this.vehicle.id) {
        return 3;
      }else{
        return false;
      }
    }
  },
  watch: {
    vehicle() {
      this.finishedLoading = false;

      if (this.vehicle.id && this.isLoggedIn) {
        this.getReservation(this.vehicle.id).then(() => {
          this.finishedLoading = true;
        });
      }

      if (!this.isLoggedIn) this.finishedLoading = true;
    }
  },
  methods: {
    howDoesItWork: function() {
      if($('.CarslGetStartedList--Mobile').is(':visible')) {
        ScrollTo.scrollTo('.CarslGetStartedList--Mobile');
      }else {
        ScrollTo.scrollTo('.CarslGetStartedListDesktop');
      }
    },
    async getReservation(vehicle_id) {
      await this.$http
        .get("bankds/graduate/rentalreservation/reserve/" + vehicle_id)
        .then(response => {
          const response_data = response.data.data;
          this.reservedUserId = response_data.id;
          this.reservedVehicleId = response_data.vehicle_id;
        })
        .catch(error => {
          if (error.response)
            swal("Error", error.response.data.message, "error");
        });
    },
    modalClosed() {
      this.modalOpen = false;
    },
    vehicleReserved() {
      $(".modalman").modal('toggle');
      this.reservedUserId = this.getAuthUserId;
      this.reservedVehicleId = this.vehicle.id;
    },
    reserveVehicle() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let data = {
            'vehicle_id': this.vehicle.id,
            'scheme_id':  this.selectedRate,
            'term_accept':this.accept_term
          }

          this.$http
            .post("bankds/graduate/rentalreservation/reserve", data)
            .then(() => {

              this.reservedUserId = this.getAuthUserId;
              this.reservedVehicleId = this.vehicle.id;
              swal(
                "Reserved",
                "Your vehicle has been reserved and a consultant will contact you shortly. Check your inbox for an email confirmation.",
                "success"
              );
            })
            .catch(error => {
              if (error.response.data.status_code === 422) {
                swal("Oops", error.response.data.message, "info");
              } else if (error.response) {
                swal("Error", error.response.data.message, "error");
              }
            });
        }
      });
    }
  }
}
</script>

<style>

</style>
