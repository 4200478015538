<template>
  <section class="GeneralPage">
    <div
      v-if="loadingWizard"
      class="loader" />
    <div
      class="container"
      style="margin-bottom: 48px;">
      <h1 class="title">
        Password Reset
      </h1>
      <hr>
      <div class="gForm gForm--WhiBg">
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-sm-6"
            style="margin: auto;">
            <div
              class="gForm gForm--WhiBg"
              @keydown.enter="savePassword()">
              <div
                :class="errors.has('password')?'has-error':''"
                class="form-group">
                <input
                  ref="password"
                  v-model="password"
                  v-validate="'required|secure_password'"
                  data-vv-as="Password"
                  type="password"
                  placeholder="New Password"
                  name="password"
                  class="form-control input-sm required">
                <span
                  v-show="errors.has('password')"
                  class="help-block">{{ errors.first('password') }}</span>
              </div><!-- end form-group -->
              <div
                :class="errors.has('confirmPassword')?'has-error':''"
                class="form-group">
                <input
                  v-model="password_confirm"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="Password Confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  class="form-control input-sm required">
                <span
                  v-show="errors.has('confirmPassword')"
                  class="help-block">{{ errors.first('confirmPassword') }}</span>
              </div><!-- end form-group -->
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-primary float-left"
                  @click="savePassword()">
                  Save Password
                </button>
              </div><!-- end form-group -->
            </div>
          </div><!-- end col -->
        </div><!-- end row -->
      </div>
    </div>
  </section>
</template>

<script>
import swal from "sweetalert2";

export default {
  name: "PasswordReset",
  data() {
    return {
      loadingWizard: false,
      password: "",
      password_confirm: ""
    };
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    savePassword() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let token = this.$route.params.token;

          const data = {
            token: token,
            password: this.password,
            password_confirmation: this.password_confirm
          };
          this.setLoading(true);
          this.$http
            .put("bankds/graduate/password/reset", data)
            .then(response => {
              if (response) {
                this.$router.push("/login");
                swal("Success", "Password updated", "success");
                this.setLoading(false);
              }
            })
            .catch(() => {
              this.setLoading(false);
              return Promise.resolve(true);
            });
        }
      });
    }
  }
};
</script>

<style>
</style>
