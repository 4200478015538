<template>
  <section class="HeadSectionTwo">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="sectiontwohead4">
          Get a head start with
        </h4>
        <h1 class="sectiontwohead1">
          Standard Bank Graduate Finance
        </h1>
        <p class="sectiontwoheadtext">
          Get on the move with Standard Bank vehicle asset finance for young
          professionals. You can choose from a wide selection of vehicles that
          we have on offer for rental. We give you competitive interest rates to
          help you move from A to B.
        </p>
      </div>

      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <a
              data-id="link_content_content links"
              data-text="standard bank graduate finance | how does it work button click"
              class="btn hdiwbtn btn-primary home car-search-btn"
              style="color: white;"
              @click="howDoesItWork()">HOW DOES IT WORK?</a>
          </div>

          <div class="col-sm-12 col-md-6">
            <router-link
              data-id="link_content_content links"
              data-text="standard bank graduate finance | faq button click"
              to="faq#rent"
              class="btn faqbtn btn-primary home car-search-btn">
              FAQ
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /FindYourVechile -->
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name: "HeadStartInfo",
  methods: {
    howDoesItWork: function() {
      if($('.CarslGetStartedList--Mobile').is(':visible')) {
        ScrollTo.scrollTo('.CarslGetStartedList--Mobile');
      }else {
        ScrollTo.scrollTo('.CarslGetStartedListDesktop');
      }
    }
  }
};
</script>

<style scoped></style>
