import swal from "sweetalert2";
import _ from "lodash";
export default function(Vue) {
  Vue.helpers = {
    processError(error) {
      let errorString = "";
      _.forEach(error.response.data.errors, function(errorObj) {
        errorString += errorObj.message + "<br>";
      });
      swal("Error", errorString, "error");
    }
  };
  Object.defineProperties(Vue.prototype, {
    $helpers: {
      get() {
        return Vue.helpers;
      }
    }
  });
}
