import { store } from "../store/index";
var jsonwebtoken = require("jsonwebtoken");
export default function(Vue) {
  Vue.auth = {
    setToken(token) {
      localStorage.setItem("token", token);
    },

    getToken() {
      var token = localStorage.getItem("token");

      if (!token) {
        return null;
      }

      var decodedToken = jsonwebtoken.decode(token);
      if (Math.floor(Date.now() / 1000) > decodedToken.exp) {
        this.logout();
        return null;
      }
      return token;
    },

    logout() {
      localStorage.removeItem("token");

      store.dispatch("clearUser");
      store.dispatch("logout");
    },

    isAuthenticated() {
      if (this.getToken()) {
        return true;
      } else {
        return false;
      }
    }
  };

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      }
    }
  });
}
